import { mapActions, mapGetters } from "vuex"
import ThemisDecision from "@/components/shared/decision"
import { FIELD_TYPES } from "@/constants"

export default {
  name      : "IssueField",
  components: {
    ThemisDecision
  },
  data(){
    return{
      showRemoveIssueFieldDialog: false,
      issueFieldToBeRemoved     : undefined
    }
  },
  computed: {
    ...mapGetters({
      issueFields         : "issueFields/issueFields",
      fieldsV2            : "fields/fieldsV2",
      optionLists         : "optionLists/optionLists",
      isIssueFieldRemoved : "issueFields/isIssueFieldRemoved",
      isRemovingIssueField: "issueFields/isRemovingIssueField"
    }),
    issueFieldId() {
      return +this.$route.params.id
    },
    issueField() {
      return this.issueFields?.find(issueField => issueField.id === this.issueFieldId)
    },
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    selectedIssueField() {
      if (this.$route.params.id) {
        return this.issueFields.find(
          issueField => issueField.id === +this.$route.params.id
        )
      }
    },
    selectedField() {
      return this.fieldsMap[this.selectedIssueField?.fieldId]
    },
    selectedFieldType() {
      return Object.values(FIELD_TYPES).find(fieldType => fieldType.value === this.selectedField.type)
    },
    optionListNameOfSelectedField() {
      return this.optionListsMap[this.selectedField.optionListId]?.name
    },
    fieldTypeNameOfSelectedField() {
      return this.selectedFieldType?.name
    },
    fieldTypeIconOfSelectedField() {
      return this.selectedFieldType?.icon
    },
    showOptionListDetails() {
      return [FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(
        this.selectedField.type
      )
    }
  },
  methods: {
    ...mapActions({
      notify          : "shared/notify",
      removeIssueField: "issueFields/removeIssueField"
    }),
    handleRemoveIssueField() {
      this.fieldToBeRemoved = this.selectedField
      this.removeIssueField(this.selectedIssueField.id)
    }
  },
  watch: {
    isIssueFieldRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "1159",
            parameters: {
              fieldSystemName: this.fieldToBeRemoved?.systemName
            }
          })
          this.showRemoveIssueFieldDialog = false
          this.fieldToBeRemoved           = undefined

          this.$router.push({
            name  : "issue-fields-and-forms",
            params: {
              id: this.issueFieldId
            }
          })
        }
      }
    },
    isRemovingIssueField: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_ISSUE_FIELD.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_ISSUE_FIELD.pActions[1].buttonProps.loading  = newValue
      }
    }

  }
}